// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    adminV2TagList(params) {
        return request({
            url: "admin/v2/tag/list",
            method: "get",
            params,
        });
    },
    adminV2TagAdd(data) {
        return request({
            url: "admin/v2/tag/add",
            method: "post",
            data,
        });
    },
    adminV2TagAdChild(data) {
        return request({
            url: "admin/v2/tag/ad/child",
            method: "post",
            data,
        });
    },
    adminV2TagDel(data) {
        return request({
            url: "admin/v2/tag/delete",
            method: "post",
            data,
        });
    },
    adminV2TagEdit(data) {
        return request({
            url: "admin/v2/tag/edit",
            method: "post",
            data,
        });
    },
    adminV2TageReorder(params) {
        return request({
            url: "admin/v2/tag/async",
            method: "get",
            params,
        });
    },
});
