export default ({ request }) => ({
    user48MessageAdd(data) {
        return request({
            url: "admin/v2/new_user/message/task/add",
            method: "post",
            data,
        });
    },
    user48MessageEdit(data) {
        return request({
            url: "admin/v2/new_user/message/task/edit",
            method: "post",
            data,
        });
    },
    user48MessageList(params) {
        return request({
            url: "admin/v2/new_user/message/task/list",
            method: "get",
            params,
        });
    },
    user48MessageDetail(params) {
        return request({
            url: "admin/v2/new_user/message/task/detail",
            method: "get",
            params,
        });
    },
    user48MessageDelete(params) {
        return request({
            url: "admin/v2/new_user/message/task/delete",
            method: "get",
            params,
        });
    },
});
