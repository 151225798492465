export default ({ request }) => ({
    getMsgV2Task(params) {
        return request({
            url: "admin/v2/message/task/list",
            method: "get",
            params,
        });
    },
    addMsgV2Task(data) {
        return request({
            url: "admin/v2/message/task/add",
            method: "post",
            data,
        });
    },
    exitMsgV2Task(data) {
        return request({
            url: "admin/v2/message/task/edit",
            method: "post",
            data,
        });
    },
    detailMsgV2Task(params) {
        return request({
            url: "admin/v2/message/task/detail",
            method: "get",
            params,
        });
    },
    exitMsgV2FilterUser(data) {
        return request({
            url: "admin/v2/message/task/filter_user",
            method: "post",
            data,
        });
    },
    exitMsgV2CountUser(data) {
        return request({
            url: "admin/v2/message/task/count_user",
            method: "post",
            data,
        });
    },
});
