// const devBaseURL = "http://localhost:3000"; //开发环境
let devBaseURL = 'http://192.168.100.143:3000'; //开发环境
const proBaseURL = 'https://work.api.sczsie.cn/'; //生产环境

if(process.env.REACT_APP_CODER_KEY === "lxx") {
    devBaseURL = 'http://localhost:3000'; //开发环境
}

export const BASE_URL = process.env.NODE_ENV === 'development' ? devBaseURL : proBaseURL;
// export const BASE_URL = proBaseURL;
export const TIMEOUT = 8000;