// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getexternalContactList(params) {
        return request({
            url: 'admin/acquisition/link/list',
            method: 'get',
            params,
        });
    },
    addexternalContactList(data) {
        return request({
            url: 'admin/acquisition/link/add',
            method: 'post',
            data,
        });
    },
    deleteexternalContactList(data) {
        return request({
            url: 'admin/acquisition/link/delete',
            method: 'post',
            data,
        });
    },
    acquisitionLinkWelcomeAdd(data) {
        return request({
            url: 'admin/acquisition/link/welcome/add',
            method: 'post',
            data,
        });
    },
    acquisitionLinkWelcomeEdit(data) {
        return request({
            url: '/admin/acquisition/link/welcome/edit',
            method: 'post',
            data,
        });
    },
});
