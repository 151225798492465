// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    acquisitionLinkUserGetTotal(params) {
        return request({
            url: 'admin/acquisition/link/user/get_total',
            method: 'get',
            params,
        });
    },
    acquisitionLinkUserAddTotal(data) {
        return request({
            url: 'admin/acquisition/link/user/add_total',
            method: 'post',
            data,
        });
    },
});
