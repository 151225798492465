// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    adminV2ChannelLinkList(params) {
        return request({
            url: 'admin/v2/channel/link/list',
            method: 'get',
            params,
        });
    },
    adminV2ChannelLinkAdd(data) {
        return request({
            url: 'admin/v2/channel/link/add',
            method: 'post',
            data,
        });
    },
    adminV2ChannelLinkGetBind(params) {
        return request({
            url: 'admin/v2/channel/link/get/bind',
            method: 'get',
            params,
        });
    },
    adminV2ChannelLinkBindLink(data) {
        return request({
            url: 'admin/v2/channel/link/bind/link',
            method: 'post',
            data,
        });
    },
    adminV2ChannelCancelBindLink(data) {
        return request({
            url: 'admin/v2/channel/link/cancel/link',
            method: 'post',
            data,
        });
    },
    adminAcquisitionLinkInfo(params) {
        return request({
            url: '/admin/acquisition/link/info',
            method: 'get',
            params,
        });
    },
    adminAcquisitionLinkTagAdd(data) {
        return request({
            url: 'admin/acquisition/link/tag/add',
            method: 'post',
            data,
        });
    },
    adminAcquisitionLinkTagDel(data) {
        return request({
            url: 'admin/acquisition/link/tag/delete',
            method: 'post',
            data,
        });
    },
});
