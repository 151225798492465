// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getCompanyList(params) {
        return request({
            url: 'admin/company/list',
            method: 'get',
            params,
        });
    },
    CompanyEditStatus(params) {
        return request({
            url: 'admin/company/edit_status',
            method: 'get',
            params,
        });
    },
    getCompanyQrCode() {
        return request({
            url: 'admin/company/qr_code',
            method: 'get',
        });
    },
    // -------------------------
    companyAddApps(data) {
        return request({
            url: 'admin/company/add/apps',
            method: 'post',
            data,
        });
    },
    companyListApps(params) {
        return request({
            url: 'admin/company/list/apps',
            method: 'get',
            params,
        });
    },
    // 部门管理
    companyDepartmentList(params) {
        return request({
            url: 'admin/company/department/list',
            method: 'get',
            params,
        });
    },
    adminCompanyDepartmentPeople(params) {
        return request({
            url: 'admin/company/department/people',
            method: 'get',
            params,
        });
    },
    companyWorkList(params) {
        return request({
            url: 'admin/company/work/list',
            method: 'get',
            params,
        });
    },
});
