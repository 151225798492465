/* eslint-disable import/no-anonymous-default-export */
const ExternalContact = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <title>画板</title>
        <g id="画板" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M12,2.08737906 C12.2240282,2.08737906 12.4480565,2.14752105 12.6469245,2.26780501 L19.7401913,6.55811873 C20.1145384,6.78454001 20.3432667,7.19019725 20.3432667,7.62769279 L20.3432667,16.3723072 C20.3432667,16.8098028 20.1145384,17.21546 19.7401913,17.4418813 L12.6469245,21.732195 C12.4480565,21.852479 12.2240282,21.9126209 12,21.9126209 C11.7759718,21.9126209 11.5519435,21.852479 11.3530755,21.732195 L4.25980873,17.4418813 C3.88546163,17.21546 3.65673326,16.8098028 3.65673326,16.3723072 L3.65673326,7.62769279 C3.65673326,7.19019725 3.88546163,6.78454001 4.25980873,6.55811873 L11.3530755,2.26780501 C11.5519435,2.14752105 11.7759718,2.08737906 12,2.08737906 Z"
                id="多边形"
                stroke="#8C8BDC"
                strokeWidth="1.5"
            ></path>
            <g id="编组" transform="translate(7.75, 7.5)" fill="#D8D8D8" stroke="#8C8BDC">
                <rect id="矩形" x="0.5" y="2.5" width="1" height="5" rx="0.5"></rect>
                <rect id="矩形备份-2" x="7.5" y="2.5" width="1" height="5" rx="0.5"></rect>
                <rect id="矩形备份" x="4" y="0.5" width="1" height="8" rx="0.5"></rect>
            </g>
        </g>
    </svg>
);

export default { ExternalContact };
