// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getEnumFixedRime(params) {
        return request({
            url: 'admin/v2/message/enum/fixed_rime',
            method: 'get',
            params,
        });
    },
    getEnumDayAlias(params) {
        return request({
            url: 'admin/v2/message/enum/day_alias',
            method: 'get',
            params,
        });
    },
    getTaskLoop(params) {
        return request({
            url: 'admin/v2/message/enum/task_loop',
            method: 'get',
            params,
        });
    },
    getExecutionStatus(params) {
        return request({
            url: 'admin/v2/message/enum/execution_status',
            method: 'get',
            params,
        });
    },
});
