/* eslint-disable import/no-anonymous-default-export */
export default ({ request }) => ({
  getUserList(data) {
    return request({
      url: "admin/user/list",
      method: "post",
      data,
    });
  },
  getUserListV2(params) {
    return request({
      url: "admin/v2/external_user/list",
      method: "get",
      params,
    });
  },
});
